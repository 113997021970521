import { Box, Button, Card, CardActions, CardContent, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { ComedyEvent, getComedyEventDetail } from "../../models/ComedyEvent";
import { useLoaderData } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import dayjs from "dayjs";
import OptionalDetail from "../shared/OptionalDetail";
import OptionalDetailList from "../shared/OptionalDetailList";
import { useContext } from "react";
import { AuthContext } from "../../context/auth-context";
import Detail from "../shared/Detail";
import EventChip from "../shared/EventChip";

export async function loader({ params }: any) {
  const eventDetail = await getComedyEventDetail(params.eventId);

  return eventDetail;
}

export default function EventDetails() {
  const { currentUser } = useContext(AuthContext);
  const comedyEvent = useLoaderData() as ComedyEvent;

  return (
    <Container maxWidth="lg">
      <Card sx={{ maxWidth:"lg", mt: 5 }}>
        {currentUser && 
          <CardActions>
            <Button component={RouterLink} to={`/events/${comedyEvent.ID}/edit`} size="small">Edit</Button>
          </CardActions>
        }
      </Card>
      <CardContent>
        <Grid container spacing={4}>
          <Grid xs={12}>
            <Typography variant="h4" component="h2">
              {comedyEvent.Title}
            </Typography>
            {
              comedyEvent?.Tagline &&
                (
                  <Typography variant="h5" color={'grey'} component="h2">
                    {comedyEvent.Tagline}
                  </Typography>     
                )
            }
          </Grid>
          <Grid xs={12} md={4}>
            <Typography sx={{ mb: "0.35em" }}>
              <EventChip eventCategory={comedyEvent.EventCategory} />
            </Typography>
            <OptionalDetail header="Presented By" detail={comedyEvent?.PresentedBy}></OptionalDetail>
            <OptionalDetail header="Headliner" detail={comedyEvent?.Headliner}></OptionalDetail>
            <OptionalDetailList header="Hosted By" detailList={comedyEvent?.HostedBy}></OptionalDetailList>
            <OptionalDetailList header="Featuring" detailList={comedyEvent?.Featuring}></OptionalDetailList>
            <Detail header="Show Date">{dayjs(comedyEvent.StartDateTime).format("dddd, MMMM D, YYYY")}</Detail>
            <OptionalDetail header="Doors Open" detail={comedyEvent.DoorsOpenTime ? dayjs(comedyEvent.DoorsOpenTime).format("h:mm A") : null}></OptionalDetail>
            <Detail header="Start Time">{dayjs(comedyEvent.StartDateTime).format("h:mm A")}</Detail>
            <Detail header="Venue">
              {comedyEvent.Venue}<br />
              {comedyEvent.Address}<br />
              {comedyEvent.City}, {comedyEvent.Province}<br />
            </Detail>
          </Grid>
          <Grid xs={12} md={8}>
            <Typography variant="overline">
              Details
            </Typography>
            <Typography sx={{ whiteSpace: "pre-wrap" }} gutterBottom paragraph>
              {comedyEvent.Description}
            </Typography>
            {comedyEvent.EventImageUrl && (
              <Box mt={2}>
                <img src={comedyEvent.EventImageUrl} alt="Event Poster" style={{ maxWidth: '100%' }} />
              </Box>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Container>
  )
}