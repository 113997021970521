import { AppBar, Button, Container, IconButton, Link, MenuItem, Toolbar, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { AuthContext } from "../context/auth-context";
import Logo from "../assets/laughing-stork-logo-grey.png";
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import React from "react";
import { AccountCircle } from "@mui/icons-material";

export default function MenuBar() {
  const { currentUser, signOut } = useContext(AuthContext);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleSignOut = () => {
    setAnchorElUser(null);
    signOut();
  }
  
  return (
    <AppBar 
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* Mobile Version */}
            <Box 
              component="img"
              sx={{
                display: { xs: 'none', md: 'flex' },
                height: 50,
                mr: 2
              }}
              alt="Laughing Stork"
              src={Logo}
            />
            <Typography 
              variant="h6"
              noWrap
              component={RouterLink}
              to="/"
              sx={{ 
                mr: 2,
                display: { xs: 'none', md:'flex' },
                fontweight: 700,
                color: 'inherit',
                textDecoration: 'none'
              }}
            >
              Laughing Stork
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }}}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{ display: { xs: 'block', md: 'none' } }}
              >
                {currentUser &&
                  <MenuItem key={"addEvent"} onClick={handleCloseNavMenu}>
                    <Typography
                      component={RouterLink}
                      to="/events/add"
                      color="inherit"
                      sx={{ 
                        textAlign: 'center',
                        textDecoration: 'none'
                      }}
                    >
                      Add Event
                    </Typography>
                  </MenuItem>
                }
                <MenuItem key={"pastEvents"} onClick={handleCloseNavMenu}>
                  <Typography
                    component={RouterLink}
                    to="/past-events"
                    color="inherit"
                    sx={{ 
                      textAlign: 'center',
                      textDecoration: 'none'
                    }}
                  >
                    Past Events
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
            {/* Desktop version */}
            <Box 
              component="img"
              sx={{
                display: { xs: 'flex', md: 'none' },
                height: 50,
                mr: 2
              }}
              alt="Laughing Stork"
              src={Logo}
            />
            <Typography 
              variant="h6"
              noWrap
              component="div"
              sx={{ 
                mr: 2,
                display: { xs: 'flex', md:'none' },
                flexGrow: 1,
                fontweight: 700,
                color: 'inherit',
                textDecoration: 'none'
              }}
            >
              <Link 
                component={RouterLink}
                sx={{ 
                  mr: 2,
                  display: { xs: 'flex', md:'none' },
                  fontweight: 700,
                  color: 'inherit',
                  textDecoration: 'none'
                }}
                to="/"
              >
                Laughing Stork
              </Link>
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {currentUser &&
                <Button
                  key={"addEvent"}
                  component={RouterLink}
                  to="/events/add"
                  onClick={handleCloseNavMenu}
                  sx={{ 
                    my: 2,
                    display: 'block',
                    color: 'inherit' 
                  }}
                >
                  Add Event
                </Button>
              }
              <Button
                key={"pastEvents"}
                component={RouterLink}
                to="/past-events"
                onClick={handleCloseNavMenu}
                sx={{ 
                  my: 2,
                  display: 'block',
                  color: 'inherit'
                }}
              >
                Past Events
              </Button>
            </Box>
            {currentUser &&
              <Box sx={{ flexGrow: 0 }}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <AccountCircle fontSize="large" />
                </IconButton>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem key={'signOut'} onClick={handleSignOut}>
                    <Typography sx={{ textAlign: 'center' }}>Logout</Typography>
                  </MenuItem>
                </Menu>
              </Box> 
            }
          </Toolbar>
        </Container>
      </AppBar>
  );
}