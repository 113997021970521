import './App.css';
import MenuBar from './menu-bar/MenuBar';
import Footer from './footer/Footer'
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider } from 'react-router-dom';
import Home, { loader as comedyEventsLoader } from './pages/home/Home';
import PastEvents, { loader as pastComedyEventsLoader } from './pages/past-events/PastEvents';
import Login from './pages/login/Login';
import AddComedyEvent, { action as addComedyEventAction } from './pages/add-event/AddComedyEvent';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { AuthProvider } from './context/auth-context';
import { ProtectedRoute } from './auth/ProtectedRoute';
import EventDetails, { loader as comedyEventDetailLoader } from './pages/event-details/EventDetails';
import EditEvent, { 
  loader as editComedyEventLoader,
  action as editComedyEventAction 
} from './pages/edit-event/EditEvent';

let router = createBrowserRouter(
  createRoutesFromElements(
    <Route 
      path="/" 
      element={<Layout />}
    >
      <Route 
        index 
        element={<Home />}
        loader={comedyEventsLoader}
      />
      <Route 
        path="login" 
        element={<Login />}
      />
      <Route 
        path="events/add"
        element={
          <ProtectedRoute>
            <AddComedyEvent />
          </ProtectedRoute>}
        action={addComedyEventAction}
      />
      <Route
        path="events/:eventId"
        element={<EventDetails />}
        loader={comedyEventDetailLoader}
      />
      <Route
        path="events/:eventId/edit"
        element={
          <ProtectedRoute>
            <EditEvent />
          </ProtectedRoute>}
        loader={editComedyEventLoader}
        action={editComedyEventAction}
      />
      <Route
        path="past-events"
        element={<PastEvents />}
        loader={pastComedyEventsLoader}
         />
    </Route>
  )
);

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <RouterProvider router={router} />
    </LocalizationProvider>
  );
}

function Layout() {
  return (
    <AuthProvider>
      <MenuBar></MenuBar>
      <Outlet />
      <Footer></Footer>
    </AuthProvider>
  );
}

export default App;
