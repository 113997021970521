import Typography from "@mui/material/Typography";

export default function OptionalDetail(props: any) {
  const header = props.header;
  const detail = props.detail;
  return (
    detail &&
      <>
        <Typography variant="overline">
          {header}
        </Typography>
        <Typography gutterBottom variant="h6">
          {detail}
        </Typography>
      </>
  );
}
