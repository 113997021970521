import { useState, ChangeEvent, useRef } from 'react';
import { Button, Box, Stack, IconButton } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';

interface ImageUploadProps {
  eventImageUrl: string;
}

function ImageUpload(props: ImageUploadProps) {
  const [image, setImage] = useState<string | null>(props.eventImageUrl);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const deleteInputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files?.[0];
    if (file) {
      setImage(URL.createObjectURL(file));
    }
  };

  const resetFileInput = () => {
    if (deleteInputRef.current) { 
      deleteInputRef.current.value = 'true';
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setImage('')
  }

  return (
    <Box>
      <Stack direction="row" spacing={2}>
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
        >
          Upload Event Image
          <input
            ref={fileInputRef}
            id="event-image"
            name="event-image"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            hidden
          />
          <input
            ref={deleteInputRef}
            id="delete-image"
            name="delete-image"
            hidden
          />

        </Button>
        {image && (
          <IconButton aria-label="delete" onClick={resetFileInput}>
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
      {image && (
          <Box mt={2}>
            <img src={image} alt="Preview" style={{ maxWidth: '100%' }} />
          </Box>
      )}
    </Box>
  );
};

export default ImageUpload;
