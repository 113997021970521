import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { ComedyEvent, getPastComedyEvents } from "../../models/ComedyEvent";
import { useLoaderData } from "react-router-dom";
import { useState } from "react";
import React from "react";
import EventCard from "../home/EventCard";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";

const homeConfig = {
  pageSize: process.env.REACT_APP_HOME_PAGE_SIZE
};

export async function loader({ params }: any) {
  const comedyEvents = getPastComedyEvents();

  return comedyEvents;
}

export default function PastEvents() {
  const pageSize = homeConfig.pageSize ? +homeConfig.pageSize : 2;
  const comedyEvents = useLoaderData() as ComedyEvent[]; // Use the proper way of doing this
  const [listings, setListings] = useState(comedyEvents);
  const [nextStartDate, setNextStartDate] = useState(getNextDate(comedyEvents, pageSize, 'StartDateTime'));
  const [nextCreatedDate, setNextCreatedDate] = useState(getNextDate(comedyEvents, pageSize, 'Created'));
  const [hasMoreListings, setHasMoreListings] = useState(comedyEvents.length > 0 && comedyEvents.length <= pageSize);
  const [nextListingsLoading, setNextListingsLoading] = useState(false);

  function getNextDate(comedyEvents: ComedyEvent[], pageSize: number, dateProperty: string): Date {
    let nextDate: Date;
    let comedyEvent: ComedyEvent;
    
    if (comedyEvents.length == 0) {
      nextDate = new Date();
    } else if (comedyEvents.length < pageSize) {
      comedyEvent = comedyEvents[comedyEvents.length - 1];
      nextDate = dateProperty === 'StartDateTime' ? comedyEvent['StartDateTime'] : comedyEvent['Created'];
    } else {
      comedyEvent = comedyEvents[pageSize - 1];
      nextDate = dateProperty === 'StartDateTime' ? comedyEvent['StartDateTime'] : comedyEvent['Created'];
    }
    
    return nextDate;
  }

  async function loadNextListings() {
    if (hasMoreListings) {
      setNextListingsLoading(true);
      const nextComedyEvents = await getPastComedyEvents(nextStartDate, nextCreatedDate);
      if (nextComedyEvents.length > 0 && nextComedyEvents.length <= pageSize) {
        const updatedListings = [...listings, ...nextComedyEvents];
        setListings(updatedListings);
      }
      if (nextComedyEvents.length < pageSize) {
        setHasMoreListings(false);
      } else {
        setNextStartDate(nextComedyEvents[pageSize-1].StartDateTime);
        setNextCreatedDate(nextComedyEvents[pageSize-1].Created);
      }

      setNextListingsLoading(false);
    }
  }
  
  return (
    <main>
      <Container sx={{ py:8}} maxWidth="md">
        <Grid container spacing={2}>
        {listings.map((comedyEvent) => (
            <React.Fragment key={comedyEvent.ID}>
              <EventCard comedyEvent={comedyEvent}></EventCard>
            </React.Fragment>
          ))}
          {hasMoreListings &&
            <Grid item xs={12} sm={12} md={12}>
              {nextListingsLoading &&
                <Box sx={{ width: '100%' }}>
                  <LinearProgress />
                </Box>}
              <Button onClick={loadNextListings} disabled={nextListingsLoading} fullWidth={true} variant="outlined">More</Button>
            </Grid>}
        </Grid>
      </Container>
    </main>
  )
}