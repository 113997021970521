import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import { useLoaderData } from "react-router-dom";
import { ComedyEvent, getComedyEvents } from "../../models/ComedyEvent";
import EventCard from "./EventCard";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const homeConfig = {
  pageSize: process.env.REACT_APP_HOME_PAGE_SIZE
};
const daysWithin = 60;

export async function loader({ params }: any) {
  const comedyEvents = getComedyEvents(undefined, undefined, daysWithin);

  return comedyEvents;
}

export default function Home() {
  const pageSize = homeConfig.pageSize ? +homeConfig.pageSize : 2;
  const comedyEvents = useLoaderData() as ComedyEvent[]; // Use the proper way of doing this
  const [listings, setListings] = useState(comedyEvents);
  const [nextStartDate, setNextStartDate] = useState(getNextDate(comedyEvents, pageSize, 'StartDateTime'));
  const [nextCreatedDate, setNextCreatedDate] = useState(getNextDate(comedyEvents, pageSize, 'Created'));
  const [hasMoreListings, setHasMoreListings] = useState(comedyEvents.length > 0 && comedyEvents.length <= pageSize);
  const [nextListingsLoading, setNextListingsLoading] = useState(false);
  const [eventsWithinDays, setEventsWithinDays] = React.useState(daysWithin.toString());
  
  function getNextDate(comedyEvents: ComedyEvent[], pageSize: number, dateProperty: string): Date {
    let nextDate: Date;
    let comedyEvent: ComedyEvent;
    
    if (comedyEvents.length == 0) {
      nextDate = new Date();
    } else if (comedyEvents.length < pageSize) {
      comedyEvent = comedyEvents[comedyEvents.length - 1];
      nextDate = dateProperty === 'StartDateTime' ? comedyEvent['StartDateTime'] : comedyEvent['Created'];
    } else {
      comedyEvent = comedyEvents[pageSize - 1];
      nextDate = dateProperty === 'StartDateTime' ? comedyEvent['StartDateTime'] : comedyEvent['Created'];
    }
    
    return nextDate;
  }

  async function loadNextListings() {
    if (hasMoreListings) {
      setNextListingsLoading(true);
      const nextComedyEvents = await getComedyEvents(nextStartDate, nextCreatedDate, Number(eventsWithinDays));
      if (nextComedyEvents.length > 0 && nextComedyEvents.length <= pageSize) {
        const updatedListings = [...listings, ...nextComedyEvents];
        setListings(updatedListings);
      }
      if (nextComedyEvents.length < pageSize) {
        setHasMoreListings(false);
      } else {
        setNextStartDate(nextComedyEvents[pageSize-1].StartDateTime);
        setNextCreatedDate(nextComedyEvents[pageSize-1].Created);
      }

      setNextListingsLoading(false);
    }
  }

  const eventsWithinDaysChange = (event: SelectChangeEvent) => {
    setEventsWithinDays(event.target.value);
    loadNextListings();
  }

  useEffect(() => {
    resetListings();
  }, [eventsWithinDays]);

  async function resetListings() {
    const comedyEvents =  await getComedyEvents(undefined, undefined, Number(eventsWithinDays)) as ComedyEvent[];
    setListings(comedyEvents);
    setHasMoreListings(comedyEvents.length > 0 && comedyEvents.length <= pageSize);
    setNextStartDate(getNextDate(comedyEvents, pageSize, 'StartDateTime'));
    setNextCreatedDate(getNextDate(comedyEvents, pageSize, 'Created'));
  }
  
  return (
    <main>
      <Container sx={{ py:8 }} maxWidth="md">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <InputLabel id="events-within-label">Comedy events within</InputLabel>
            <Select
              labelId="events-within-label"
              id="events-within-select"
              value={eventsWithinDays}
              label="Events within"
              onChange={eventsWithinDaysChange}
              fullWidth={true}
            >
              <MenuItem value={60}>60 Days</MenuItem>
              <MenuItem value={30}>30 Days</MenuItem>
              <MenuItem value={15}>15 Days</MenuItem>
              <MenuItem value={5}>5 Days</MenuItem>
              <MenuItem value={1}>1 Day</MenuItem>
            </Select>
          </Grid>
          {listings.map((comedyEvent) => (
            <React.Fragment key={comedyEvent.ID}>
              <EventCard comedyEvent={comedyEvent}></EventCard>
            </React.Fragment>
          ))}
          {hasMoreListings &&
            <Grid item xs={12} sm={12} md={12}>
              {nextListingsLoading &&
                <Box sx={{ width: '100%' }}>
                  <LinearProgress />
                </Box>}
              <Button onClick={loadNextListings} disabled={nextListingsLoading} fullWidth={true} variant="outlined">More</Button>
            </Grid>}
        </Grid>
      </Container>
    </main>
  )
}