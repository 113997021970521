import Typography from "@mui/material/Typography";

export default function Detail(props: any) {
  const header = props.header;
  const children = props.children;
  return (
    <>
      <Typography variant="overline">
        {header}
      </Typography>
      <Typography gutterBottom variant="h6">
        {children}
      </Typography>
    </>
  );
}
