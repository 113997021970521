import Typography from "@mui/material/Typography";
import React from "react";

export default function OptionalDetailList(props: any) {
  const header = props.header;
  const detailList = props.detailList;

  return detailList && detailList.length > 0 && (
      <>
        <Typography variant="overline">
          {header}
        </Typography>
        <Typography gutterBottom variant="h6">
          {detailList.map((detail: any, index: number) => (
            <React.Fragment key={index}>
              {detail}<br />
            </React.Fragment>
          ))}
        </Typography>
      </>
  );
}
