import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { ComedyEvent } from "../../models/ComedyEvent";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import { Link as RouterLink } from "react-router-dom";
import EventChip from "../shared/EventChip";

type EventCardProps = {
  comedyEvent: ComedyEvent
};

export default function EventCard({ comedyEvent }: EventCardProps) {
  return (
    <Grid item xs={12} sm={12} md={12}>
      <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography variant="h5" component="h2">
              {comedyEvent.Title}
            </Typography>
          <Typography color="text.secondary" noWrap={true}>
            {comedyEvent.Tagline ? comedyEvent.Tagline : comedyEvent.Description}
          </Typography>
          <Typography variant="subtitle1">
            {dayjs(comedyEvent.StartDateTime).format("dddd, MMMM D, YYYY @ h:mm A")}
          </Typography>
          {comedyEvent.Venue &&
            <Typography variant="subtitle2">
              {comedyEvent.Venue}
            </Typography>
          }
          {comedyEvent.City &&
            <Typography gutterBottom variant="subtitle2">
              {comedyEvent.City}, {comedyEvent.Province}
            </Typography>
          }
          <EventChip eventCategory={comedyEvent.EventCategory} />
        </CardContent>
        <CardActions>
          <Button component={RouterLink} target="_blank" to={`/events/${comedyEvent.ID}`} size="small">Details</Button>
        </CardActions>
      </Card>
    </Grid>
  );
}
