import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from './firebase';

export class FileService {
  imagePath = "images/event";
  re = /(?:\.([^.]+))?$/;

  uploadEventImage(eventId: string, file: File): Promise<string> {
    const fileExtension = this.re.exec(file.name)![1];
    const eventImageRef = ref(storage, `${this.imagePath}/${eventId}.${fileExtension}`);

    return uploadBytes(eventImageRef, file).then((snapshot) => {
      return getDownloadURL(snapshot.ref).then((downloadUrl) => {
        return downloadUrl;
      })  
    });
  }

  deleteEventImage(eventId: string, fileExt: string) {
    const eventImageRef = ref(storage, `${this.imagePath}/${eventId}.${fileExt}`);

    deleteObject(eventImageRef).then(() => {
    }).catch((error) => {
      console.log('Error deleting file!');
    });
  }
}