import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, NextOrObserver, onAuthStateChanged, signInWithEmailAndPassword, signOut, User } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

const app = initializeApp(firebaseConfig);
export const firestoreDb = getFirestore(app)
export const auth = getAuth(app)
export const storage = getStorage(app);

export const signInUser = async (
  email: string,
  password: string
) => {
  if (!email && !password) return;

  return await signInWithEmailAndPassword(auth, email, password)
}

export const userStateListener = (callback: NextOrObserver<User>) => {
  return onAuthStateChanged(auth, callback)
}

export const signOutUser = async () => await signOut(auth);