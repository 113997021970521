import { addDoc, collection, doc, getDoc, getDocs, updateDoc, serverTimestamp, orderBy, startAfter, query, limit, where, DocumentData, Query, Timestamp } from 'firebase/firestore';
import { NewComedyEvent, ComedyEvent, UpdateComedyEvent } from '../models/ComedyEvent';
import { firestoreDb } from './firebase';

const homeConfig = {
    pageSize: process.env.REACT_APP_HOME_PAGE_SIZE
  };

const comedyEventsPath = "comedyEvents"
const comedyEventColRef = collection(firestoreDb, comedyEventsPath);

export class ComedyEventService {

    async getAll(startAfterDateTime?: Date, createdAfterDateTime?: Date, daysWithin?: number): Promise<ComedyEvent[]> {
        const homePageSize = homeConfig.pageSize ? +homeConfig.pageSize : 2;
        daysWithin = daysWithin ? daysWithin : 30;
        const today = new Date();
        let latestDate = new Date();
        latestDate.setDate(latestDate.getDate() + daysWithin);
        let q = query(comedyEventColRef, 
            where("start_datetime", "<=", latestDate),
            where("start_datetime", ">=", today),
            orderBy("start_datetime"),
            orderBy("created"),
            limit(homePageSize))
        if (startAfterDateTime) {
            q = query(comedyEventColRef, 
                where("start_datetime", "<=", latestDate),
                where("start_datetime", ">=", today),
                orderBy("start_datetime"),
                orderBy("created"),
                startAfter(startAfterDateTime, createdAfterDateTime),
                limit(homePageSize))
        }

        return this.getComedyEventsFromQuery(q);
    }

    async getAllPastEvents(startAfterDateTime?: Date, createdAfterDateTime?: Date): Promise<ComedyEvent[]> {
        const homePageSize = homeConfig.pageSize ? +homeConfig.pageSize : 2;
        const today = new Date();
        let q = query(comedyEventColRef, 
            where("start_datetime", "<=", today),
            orderBy("start_datetime", "desc"),
            orderBy("created", "desc"),
            limit(homePageSize))
        if (startAfterDateTime) {
            q = query(comedyEventColRef, 
                where("start_datetime", "<=", today),
                orderBy("start_datetime", "desc"),
                orderBy("created", "desc"),
                startAfter(startAfterDateTime, createdAfterDateTime),
                limit(homePageSize))
        }

        return this.getComedyEventsFromQuery(q);
    }

    async createComedyEvent(addComedyEvent: NewComedyEvent): Promise<string> {
        const data = {
            created: serverTimestamp(),
            created_by: addComedyEvent.CreatedBy,
            event_category: addComedyEvent.EventCategory,
            event_title: addComedyEvent.Title,
            tagline: addComedyEvent.Tagline,
            description: addComedyEvent.Description,
            headliner: addComedyEvent.Headliner,
            featuring: addComedyEvent.Featuring,
            hosted_by: addComedyEvent.HostedBy,
            presented_by: addComedyEvent.PresentedBy,
            venue: addComedyEvent.Venue,
            event_img_url: addComedyEvent.EventImageUrl ? addComedyEvent.EventImageUrl : null,
            address: addComedyEvent.Address,
            city: addComedyEvent.City,
            province: addComedyEvent.Province,
            doors_open_time: addComedyEvent.DoorsOpenTime ? Timestamp.fromDate(addComedyEvent.DoorsOpenTime) : null,
            start_datetime: Timestamp.fromDate(addComedyEvent.StartDateTime)
        };
        const docRef = await addDoc(comedyEventColRef, data);

        return docRef.id;
    }

    async updateComedyEvent(updateComedyEvent: UpdateComedyEvent): Promise<string> {
        const docRef = doc(firestoreDb, comedyEventsPath, updateComedyEvent.ID);

        const data = {
            updated: serverTimestamp(),
            updated_by: updateComedyEvent.UpdatedBy,
            event_category: updateComedyEvent.EventCategory,
            event_title: updateComedyEvent.Title,
            tagline: updateComedyEvent.Tagline,
            description: updateComedyEvent.Description,
            headliner: updateComedyEvent.Headliner,
            featuring: updateComedyEvent.Featuring,
            hosted_by: updateComedyEvent.HostedBy,
            presented_by: updateComedyEvent.PresentedBy,
            venue: updateComedyEvent.Venue,
            event_img_url: updateComedyEvent.EventImageUrl,
            address: updateComedyEvent.Address,
            city: updateComedyEvent.City,
            province: updateComedyEvent.Province,
            doors_open_time: updateComedyEvent.DoorsOpenTime ? Timestamp.fromDate(updateComedyEvent.DoorsOpenTime) : null,
            start_datetime: Timestamp.fromDate(updateComedyEvent.StartDateTime)
        };
        await updateDoc(docRef, data);

        return docRef.id;
    }

    async updateComedyEventImageUrl(eventId: string, eventImageUrl: string) {
        const docRef = doc(firestoreDb, comedyEventsPath, eventId);

        const data = {
            event_img_url: eventImageUrl
        }
        await updateDoc(docRef, data);

        return docRef.id;
    }

    async getComedyEvent(id: string) : Promise<ComedyEvent> {
        let comedyEvent = {} as ComedyEvent;
        const docRef = doc(firestoreDb, comedyEventsPath, id);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
            comedyEvent.ID = docSnapshot.id;
            comedyEvent.EventCategory = docSnapshot.data().event_category;
            comedyEvent.Title = docSnapshot.data().event_title;
            comedyEvent.Tagline = docSnapshot.data().tagline;
            comedyEvent.Description = docSnapshot.data().description;
            comedyEvent.Headliner = docSnapshot.data().headliner;
            comedyEvent.Featuring = docSnapshot.data().featuring;
            comedyEvent.HostedBy = docSnapshot.data().hosted_by;
            comedyEvent.PresentedBy = docSnapshot.data().presented_by;
            comedyEvent.EventImageUrl = docSnapshot.data().event_img_url;
            comedyEvent.Venue = docSnapshot.data().venue;
            comedyEvent.Address = docSnapshot.data().address;
            comedyEvent.City = docSnapshot.data().city;
            comedyEvent.Province = docSnapshot.data().province;
            comedyEvent.DoorsOpenTime = docSnapshot.data().doors_open_time ? docSnapshot.data().doors_open_time.toDate() : null;
            comedyEvent.StartDateTime = docSnapshot.data().start_datetime.toDate();
            comedyEvent.Created = docSnapshot.data().created;
        }

        return comedyEvent;
    }

    private async getComedyEventsFromQuery(query: Query<DocumentData>): Promise<ComedyEvent[]> {
        let comedyEvents: ComedyEvent[] = [];

        const querySnapshot = await getDocs(query);
        querySnapshot.forEach((doc) => {
            comedyEvents.push({ 
                ID: doc.id,
                EventCategory: doc.data().event_category,
                Title: doc.data().event_title,
                Tagline: doc.data().tagline,
                Description: doc.data().description,
                Headliner: doc.data().headliner,
                Featuring: doc.data().featuring,
                HostedBy: doc.data().hosted_by,
                PresentedBy: doc.data().presented_by,
                EventImageUrl: doc.data().event_img_url,
                Venue: doc.data().venue,
                Address: doc.data().address,
                City: doc.data().city,
                Province: doc.data().province,
                DoorsOpenTime: doc.data().doors_open_time ? doc.data().doors_open_time.toDate() : null,
                StartDateTime: doc.data().start_datetime.toDate(),
                Created: doc.data().created.toDate()
            });
        })

        return comedyEvents;
    }
}