import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { NewComedyEvent } from "../../models/ComedyEvent";
import { Form, redirect, useNavigate } from "react-router-dom";
import { ComedyEventService } from "../../services/comedyEvent.service";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat"
import { FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { useContext, useState } from "react";
import provinceCityData  from "../../assets/province-city.json";
import comedyMetaData from "../../assets/comedy-meta.json";
import { AuthContext } from "../../context/auth-context";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ImageUpload from "../shared/ImageUpload";
import { FileService } from "../../services/file.service";

dayjs.extend(customParseFormat);

const comedyEventService = new ComedyEventService();

function extractArrayFieldValues(propName: string, formData: FormData): string[] {
  let currentIndex = 0;
  let extractedValue = '';
  let extractedValues = [];
  let extractValues = true;
  while (extractValues) {
    extractedValue = formData.get(`${propName}[${currentIndex}]`) as string;
    if (extractedValue) {
      extractedValues.push(extractedValue);
      currentIndex += 1;
    } else {
      extractValues = false;
    }
  }

  return extractedValues;
}

export async function action({ request }: any) {
  const formData = await request.formData();
  const showDate = (formData.get('show-date') as string).replace(/[^\x20-\x7E]/g, "");
  const doorsOpenTime = (formData.get('doors-open-time') as string).replace(/[^\x20-\x7E]/g, "");
  const showStartTime = (formData.get('show-start-time') as string).replace(/[^\x20-\x7E]/g, "");
  const selectedFile = (formData.get('event-image') as File);
  
  const newComedyEvent :NewComedyEvent = {
    EventCategory: formData.get('event-category') as string,
    Title: formData.get('title') as string,
    Tagline: formData.get('tagline') as string,
    Description: formData.get('description') as string,
    Headliner: formData.get('headliner') as string,
    Featuring: extractArrayFieldValues('featuringInputFields', formData),
    HostedBy: extractArrayFieldValues('hostInputFields', formData),
    PresentedBy: formData.get('presented-by') as string,
    Venue: formData.get('venue') as string,
    Address: formData.get('address') as string,
    City: formData.get('city') as string,
    Province: formData.get('province') as string,
    DoorsOpenTime: doorsOpenTime ? dayjs(doorsOpenTime, 'h:mm A').toDate() : null,
    StartDateTime: dayjs(`${showDate} ${showStartTime}`, 'M / D / YYYY h:mm A').toDate(),
    CreatedBy: formData.get('user-id') as string
  }
  const eventId = await comedyEventService.createComedyEvent(newComedyEvent);
  
  if (selectedFile.size > 0 && selectedFile.name !== '') {
    const fileService = new FileService();
    const eventImageUrl = await fileService.uploadEventImage(eventId, selectedFile);
    await comedyEventService.updateComedyEventImageUrl(eventId, eventImageUrl);
  }

  // NOTE: Leaving this here for testing in case I want to test more stuff.
  // const promise = new Promise((resolve) => {
  //   console.log(newComedyEvent);
  //   resolve("resolved");
  // });

  return redirect("/");
}

export default function AddComedyEvent() {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const [featuringInputFields, setGuestInputFields] = useState(['']);
  const handleGuestEventChange = (index: number, e: any) => {
    let data = [...featuringInputFields];
    data[index] = e.target.value;
    setGuestInputFields(data);
  }
  const deleteGuestInputField = (index: number) => {
    let data = [...featuringInputFields];
    data.splice(index, 1);
    setGuestInputFields(data);
  }
  const addGuestInputField = () => {
    setGuestInputFields([...featuringInputFields, '']);
  }
  const handleGuestInputFieldOnKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addGuestInputField();
    }
  }

  const [hostInputFields, setHostInputFields] = useState(['']);
  const handleHostEventChange = (index: number, e: any) => {
    let data = [...hostInputFields];
    data[index] = e.target.value;
    setHostInputFields(data);
  }
  const addHostInputField = () => {
    setHostInputFields([...hostInputFields, '']);
  }
  const deleteHostInputField = (index: number) => {
    let data = [...hostInputFields];
    data.splice(index, 1)
  }
  const handleHostInputFieldOnKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addHostInputField();
    }
  }

  const [eventCategory, setEventCategory] = useState('');
  const handleEventCategoryChange = (event: SelectChangeEvent) => {
    setEventCategory(event.target.value);
  }
  const eventCategoryList:any = [];
  comedyMetaData.filter(x => x.type === 'event-category').forEach(ev => eventCategoryList.push({ name: ev.name, value: ev.id }));


  const [city, setCity] = useState('');
  const handleCityChange = (event: SelectChangeEvent) => {
    setCity(event.target.value as string);
  }

  const [cities, setCities] = useState(['']);
  
  const [province, setProvince] = useState('');
  const handleProvinceChange = (event: SelectChangeEvent) => {
    setProvince(event.target.value as string);
    const cityList: string[] = [];
    provinceCityData.find(x => x.name === event.target.value)?.cities.forEach(city => cityList.push(city.name));
    setCities(cityList);
  }

  const provinceList: string[] = [];
  provinceCityData.forEach(prov => provinceList.push(prov.name));
  const [provinces] = useState(provinceList);

  const navigateBackHandler = () => {
    if (window.history?.length && window.history.length > 1) {
      navigate(-1);
   } else {
      navigate('/', { replace: true });
   }
  }

  return (
    <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
      <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          New Comedy Event
        </Typography>
        <Form method="post" encType="multipart/form-data">
          <Grid container spacing={3}>
            <input type="hidden" name="user-id" value={currentUser?.uid} />
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="event-category">Event Category</InputLabel>
                <Select
                  required
                  labelId="event-category-select-label"
                  id="event-category-select"
                  name="event-category"
                  value={eventCategory}
                  label="Event Category"
                  onChange={handleEventCategoryChange}
                >
                  {eventCategoryList.map((eventCategoryItem:any) => (
                    <MenuItem key={eventCategoryItem.name} value={eventCategoryItem.value}>{eventCategoryItem.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="title"
                name="title"
                label="Event Title"
                fullWidth
                autoComplete="event-title"
                variant="standard"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="tagline"
                name="tagline"
                label="Tagline"
                fullWidth
                autoComplete="tagline"
                variant="standard"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="description"
                name="description"
                label="Description"
                multiline
                rows={12}
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="headliner"
                name="headliner"
                label="Headliner"
                fullWidth
                autoComplete="headliner"
                variant="standard"
              />
            </Grid>
            {featuringInputFields.map((input, index) => (
              <React.Fragment key={index}>
                <Grid item xs={10}>
                  <TextField
                    autoFocus={featuringInputFields.length > 1}
                    label="Featuring"
                    name={`featuringInputFields[${index}]`}
                    value={input || ""}
                    onChange={(e) => handleGuestEventChange(index, e)}
                    onKeyDown={handleGuestInputFieldOnKeyDown}
                    fullWidth
                    autoComplete="name"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={2} mt={2}>
                  <IconButton disabled={featuringInputFields.length === 1} aria-label="delete" onClick={() => deleteGuestInputField(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </React.Fragment>
            ))}
            <Grid item xs={12}>
              <Button onClick={addGuestInputField} variant="contained" startIcon={<AddCircleIcon />}>
                  Guest
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="presented-by"
                name="presented-by"
                label="Presented By"
                fullWidth
                autoComplete="presented-by"
                variant="standard"
              />
            </Grid>
            {hostInputFields.map((input, index) => {
              return (
                <React.Fragment key={index}>
                  <Grid item xs={10}>
                    <TextField
                      autoFocus={hostInputFields.length > 1}
                      label="Host"
                      name={`hostInputFields[${index}]`}
                      value={input || ""}
                      onChange={(e) => handleHostEventChange(index, e)}
                      onKeyDown={handleHostInputFieldOnKeyDown}
                      fullWidth
                      autoComplete="name"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} mt={2}>
                    <IconButton disabled={hostInputFields.length === 1} aria-label="delete" onClick={() => deleteHostInputField(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </React.Fragment>
              )
            })}
            <Grid item xs={12}>
              <Button onClick={addHostInputField} variant="contained" startIcon={<AddCircleIcon />}>
                  Host
              </Button>
            </Grid>
            <Grid item xs={12}>
              <ImageUpload eventImageUrl="" />
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                label="Show Date"
                slots={{
                  textField: TextField
                }}
                slotProps={{
                  textField: {
                    id: "show-date",
                    name: "show-date",
                    required: true,
                    fullWidth: true
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TimePicker 
                label="Doors Open"
                format="h:mm A"
                slots={{
                  textField: TextField
                }}
                slotProps={{
                  textField: {
                    id: "doors-open-time",
                    name: "doors-open-time",
                    fullWidth: true
                  },
                  actionBar: {
                    actions: ['clear']
                  }
                }}
                 />
            </Grid>
            <Grid item xs={12}>
              <TimePicker
                label="Show Start Time"
                format="h:mm A"
                slots={{
                  textField: TextField
                }}
                slotProps={{
                  textField: {
                    id: "show-start-time",
                    name: "show-start-time",
                    required: true,
                    fullWidth: true
                  },
                  actionBar: {
                    actions: ['clear']
                  }
                }}
                 />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="venue"
                name="venue"
                label="Venue"
                fullWidth
                autoComplete="venue"
                variant="standard"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="address"
                name="address"
                label="Address"
                fullWidth
                autoComplete="address"
                variant="standard"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="province">Province</InputLabel>
                <Select
                  required
                  labelId="province-select-label"
                  id="province-select"
                  name="province"
                  value={province}
                  label="Province"
                  onChange={handleProvinceChange}
                >
                  {provinces.map((provinceItem) => (
                    <MenuItem key={provinceItem} value={provinceItem}>{provinceItem}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="city">City</InputLabel>
                <Select
                  required
                  labelId="city-select-label"
                  id="city-select"
                  name="city"
                  value={city}
                  label="City"
                  onChange={handleCityChange}
                >
                  {cities.map((cityItem) => (
                    <MenuItem key={cityItem} value={cityItem}>{cityItem}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 3, ml: 1 }}
            >
              Create
            </Button>
            <Button
              onClick={navigateBackHandler}
              variant="outlined"
              sx={{ mt: 3, ml: 1 }}
            >
              Cancel
            </Button>
          </Box>
        </Form>
      </Paper>
    </Container>
  )
}