import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../context/auth-context";

export const ProtectedRoute = ({ children }: { children:JSX.Element }) => {
  const { currentUser } = useContext(AuthContext);
  let location = useLocation();

  if (!currentUser) {
    // user is not authenticated
    return <Navigate to="/" state={ { from: location } } replace />;
  }

  return children;
};
