import Chip from "@mui/material/Chip";
import comedyMetaData from "../../assets/comedy-meta.json";

export default function EventChip(props: any) {
  const eventCategory = props.eventCategory;

  const getEventChipColor = (categoryId: string) => {
    switch (categoryId) {
      case '1':
        return 'success';
      case '2':
        return 'warning';
      case '3':
        return 'secondary';
      default:
        return 'default';
    }
  }
  
  return (
    <Chip 
      label={comedyMetaData.filter(item => item.id.toString() === eventCategory).map(x => x.name)}
      color={getEventChipColor(eventCategory)}
      size='small'
    />
  );
}
