import { ComedyEventService } from "../services/comedyEvent.service"

const comedyEventService = new ComedyEventService();

export interface ComedyEvent {
    ID: string
    EventCategory: string
    Title: string
    Tagline: string
    Description: string
    Headliner: string
    Featuring: string[]
    HostedBy: string[]
    PresentedBy: string
    EventImageUrl: string
    Venue: string
    Address: string
    City: string
    Province: string
    DoorsOpenTime : Date | null
    StartDateTime: Date
    Created: Date
}

export interface NewComedyEvent {
    EventCategory: string
    Title: string
    Tagline: string
    Description: string
    Headliner: string
    Featuring: string[]
    HostedBy: string[]
    PresentedBy: string
    EventImageUrl?: string
    Venue: string
    Address: string
    City: string
    Province: string
    DoorsOpenTime: Date | null
    StartDateTime: Date
    CreatedBy?: string
}

export interface UpdateComedyEvent extends NewComedyEvent {
    ID: string
    UpdatedBy: string
}

export function getComedyEvents(startAfterDateTime?: Date, createdAfterDateTime?: Date, daysWithin?: number): Promise<ComedyEvent[]> {
    return comedyEventService.getAll(startAfterDateTime, createdAfterDateTime, daysWithin);
}

export function getPastComedyEvents(startAfterDateTime?: Date, createdAfterDateTime?: Date): Promise<ComedyEvent[]> {
    return comedyEventService.getAllPastEvents(startAfterDateTime, createdAfterDateTime);
}

export function getComedyEventDetail(id: string): Promise<ComedyEvent> {
    return comedyEventService.getComedyEvent(id);
}
